<router>
{
    name: 'Sales Tool Part Groups',
}
</router>
<template lang="pug">
v-container(fluid)
    v-row
        v-col(cols='12')
            PartGroups
</template>
<script>

import PartGroups from '@/components/salestool/partgroups.vue';
export default {
    meta: {
        role: 'satpartgroups'
    },
    components: {
        PartGroups
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {

    }
};
</script>

<style scoped>
</style>
