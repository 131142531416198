<template lang="pug">

v-container
    v-row
        v-col(cols='12')
            v-card.mb-12(v-for='(type, groupTypeIndex) in groupTypes', light, :key='groupTypeIndex')
                v-card-title.brand.white--text.py-2.mb-3 {{type.group_name}}
                v-card-text
                    v-row(v-for='(category, categoryIndex) in groupCategories', :key='categoryIndex')
                        v-col
                            v-card.ma-1(light)
                                v-card-title.brand.white--text.py-2 {{category.group_category_name}}
                                v-card-text
                                    v-row
                                        v-col(v-for='(group, groupIndex) in groupsByCat(category.id)', v-if='group.active', cols='12', md='4', :key='groupIndex')
                                            v-card.ma-5( :key='group.id')
                                                v-card-title {{group.group_name}}
                                                v-card-subtitle.font-italic(class='text-truncate')
                                                    span(col-2, class='text-truncate') {{group.group_description}}
                                                v-card-actions
                                                    v-btn(small, color='foreground', @click='editGroup(category, group)') Edit
                                                    v-btn.white--text(small, color='brand', @click='showGroupPreview(category, group)') Preview
                                                    v-btn(small, color='red', @click='showDeleteDialog(group)') Delete
                                                    slot(name='cardButtons')
                                v-card-actions
                                    v-btn(small, color='green', @click='showGroupDialog(category)') Add to {{category.group_category_name}}

    v-dialog(v-model='displayPreview', max-width="50%")
        v-card
            v-card-title.text-h5 {{ selectedGroup.group_name }}
            v-card-subtitle {{selectedCategory.group_category_name}}
            v-card-text
                v-row
                    span.ml-3 {{ selectedGroup.group_description || 'No description provided' }}
                v-row.mt-5
                    span.ml-3 Parts:
                v-row
                    v-chip.ml-3(v-for='part in selectedParts', :key='part.id') {{part.product_id}}

    v-dialog(v-model='showDeleteModal', class='mx-auto', max-width='500')
        v-card(class='mx-auto', max-width='500')
            v-card-title.text-h5 Delete {{selectedGroup.group_name}}?
            v-card-subtitle.mt-3 Deleting part groups is permanent
            v-card-actions
                v-btn(small, @click='showDeleteModal = false') Cancel
                v-btn(small color='red', @click='deleteGroup(selectedGroup)') Delete
                slot(name='cardButtons')
    //- New Group
    v-form(v-model='validForm')
        v-dialog(v-model='showNewGroupModal', max-width='50vw')
            v-card
                v-card-title Add new part group to {{newGroupCategory.group_category_name}}
                v-card-text
                    v-row
                        v-col
                            v-text-field.white--text(v-model='newGroupCategory.group_category_name', placeholder='Group Category', outlined, disabled)
                        v-col
                            v-text-field.white--text(v-model='newGroupName', placeholder='Group Name', outlined, :rules='nameValidation')
                    v-row
                        v-col
                            v-textarea.white--text(v-model='newGroupDescription', placeholder='Group Description', outlined, :rules='descriptionValidation')
                    v-row
                        v-col
                            v-autocomplete(v-model='selectedParts', chips, deletable-chips, clearable, multiple, :items='partData', item-text='product_id', item-value='product_id')
                    v-row
                        v-col
                            v-btn.white--text(:disabled='selectedParts.length == 0 || newGroupName == "" || !validForm', @click='addGroup(newGroupCategory, newGroupName, newGroupDescription, selectedParts)', color='green') Add Group

                v-overlay(absolute, :value='busy')
                    .text-h6 Adding Group...
                    v-progress-linear(indeterminate)
    //- Edit Group
    v-form(v-model='validForm')
        v-dialog(v-if='editingGroup',v-model='showEditGroupModal', max-width='50vw')
            v-card
                v-card-title Editing {{editingGroup.group_name}}
                v-card-text
                    v-row
                        v-col
                            v-text-field.white--text(label='Group Type', v-model='editingGroup.category.group_category_name', placeholder='Group Category', outlined, disabled)
                        v-col
                            v-text-field.white--text(label='Name', v-model='editingGroup.group_name', placeholder='Group Name', outlined, :rules='nameValidation')
                    v-row
                        v-col
                            v-textarea.white--text(label='Description', v-model='editingGroup.group_description', placeholder='Group Description', outlined, :rules='descriptionValidation')
                    v-row
                        v-col
                            v-autocomplete(label='Parts', v-model='editingGroup.parts', chips, deletable-chips, clearable, multiple, :items='partData', item-text='product_id', item-value='product_id')
                v-card-actions
                    v-spacer
                    v-btn.white--text(@click='showEditGroupModal = false', color='brand') Cancel
                    v-btn.white--text(:disabled='editingGroup.parts.length == 0 || editingGroup.group_name == "" || !validForm', @click='saveGroup(editingGroup)', color='green') Save Group

                v-overlay(absolute, :value='busy')
                    .text-h6 Saving Group...
                    v-progress-linear(indeterminate)


    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import {cloneDeep} from 'lodash';
export default {
    data: () => ({
        dti,
        validForm: true,
        message: '',
        busy: false,
        groupTypes: [],
        groupCategories: [],
        groups: [],
        groupParts: [],
        partData: [],
        displayPreview: false,
        showNewGroupModal: false,
        showNewPartModal: false,
        showEditGroupModal: false,
        showDeleteModal: false,
        selectedParts: [],
        selectedGroup: '',
        selectedCategory: [],

        newGroupName: '',
        newGroupCategory: '',
        newGroupProductID: '',
        newGroupDescription: '',

        editingGroup: undefined,

        nameValidation: [
            v => !!v || "This field is required",
            v => ( v != ' ') || "This field is required",
            v => ( v.length > 0) || "Group Name cannot be empty",
            v => ( v.length <= 100 ) || "Group Name should be less than 100 characters",
        ],
        descriptionValidation: [
            v => !!v || "This field is required",
            v => ( v.length > 0) || "Description cannot be empty",
            v => ( v.length <= 1000 ) || "Description should be less than 1000 characters",
        ],
    }),
    methods: {
        editGroup (category, group) {
            this.editingGroup = cloneDeep(group);
            this.editingGroup.category = category;
            this.editingGroup.parts = this.getParts(this.editingGroup);
            this.showEditGroupModal = true;
        },
        async saveGroup (group) {
            const ogGroup = this.groups.find(g => g.id == group.id);
            const ogParts = this.getParts(ogGroup).map(part => part.product_id);
            const newParts = group.parts;
            const addParts = newParts.filter(part => !ogParts.includes(part));
            const deleteParts = ogParts.filter(part => !newParts.includes(part));

            let result = await this.sendCommand({
                action: 'updatePartGroup',
                parameters: group
            });

            if(addParts.length) {
                addParts.forEach(part => {
                    this.addPart(group, part);
                });
            }

            if(deleteParts.length) {
                deleteParts.forEach(part => {
                    const partData = this.groupParts.find(p=> p.group_id == ogGroup.id && p.product_id == part);
                    if(partData) {
                        this.deletePart(partData);
                    }
                    else {
                        console.error(`Cannot find part: ${partData}`);
                    }
                });
            }

            this.refreshData();
            this.showEditGroupModal = false;
        },
        async addGroup (category, group, desc, selectedParts) {

            //diagnostics
            console.log(category);
            console.log(group);
            console.log(desc);
            selectedParts.forEach(part => {
                console.log(part);
            });
            //diagnostics

            let newGroup = {
                id: dti.makeuuid(),
                group_name: group,
                group_description: desc,
                group_category: category.id,
            };

            let result = await this.sendCommand({
                action: 'addNewPartGroup',
                parameters: newGroup
            });

            console.log("Adding new group", result);

            if(result) {
                for (let part of selectedParts) {
                    await this.addPart(newGroup, part);
                }
            }
            this.refreshData();
            this.showNewGroupModal = false;

        },
        async addPart (group, product_id) {
            if (product_id) {
                let entry = {
                    product_id: product_id,
                    group_id: group.id,
                    qty: 1,
                    id: dti.makeuuid()
                };
                let result = await this.sendCommand({
                    action: 'addPartToGroup',
                    parameters: entry
                });
                console.log('savenewPart', result);
                if (result) {
                    //this.groups.push(user);
                    console.log("added part " + product_id);
                } else {
                    console.log('error adding part');
                    this.message = `Error adding "${product_id}"`;
                }
            }
            this.refreshData();
        },
        async deletePart (partData) {
            if (partData) {
                let entry = {
                    id: partData.id,
                };
                let result = await this.sendCommand({
                    action: 'deletePartFromGroup',
                    parameters: entry
                });
                if (result) {
                    console.log("deleted part " + partData.product_id);
                } else {
                    console.log('error adding part');
                    this.message = `Error adding "${partData.product_id}"`;
                }
            }
            this.refreshData();
        },
        async deleteGroup (group) {

            let result = await this.sendCommand({
                action: 'deletePartGroup',
                parameters: {
                    id: group.id
                }
            });

            this.refreshData();
            this.showDeleteModal = false;
        },
        showGroupPreview (category, group) {
            this.displayPreview = true;
            this.selectedParts = this.getParts(group);
            this.selectedGroup = group;
            this.selectedCategory = category;
        },
        showPartDialog (group_name) {
            this.showNewPartModal = true;
            this.selectedParts = [];
            this.selectedGroup = group_name;
            this.selectedCategory = "";
        },
        showGroupDialog (category){
            this.showNewGroupModal = true;
            this.selectedParts = [];
            this.selectedGroup = "";
            this.newGroupCategory = category;
            this.newGroupDescription = "";
            this.newGroupName = '';
        },
        showDeleteDialog (group){
            this.showDeleteModal = true;
            this.selectedParts = [];
            this.selectedGroup = group;
            this.selectedCategory = "";
        },
        groupsByCat (categoryID) {
            let filteredGroups = [];

            if (this.groups.length > 0 && this.groupParts.length > 0)
            {
                filteredGroups = this.groups.filter(obj => {
                    return obj.group_category === categoryID;
                });
                filteredGroups.forEach(group =>{
                    let groupParts = this.groupParts.filter(obj => {
                        return obj.group_category === group.id;
                    });
                    group.parts = groupParts;
                });
            }

            return filteredGroups;
        },
        getParts (group){
            let filteredParts = this.groupParts.filter(obj => {
                return obj.group_id === group.id;
            });

            return filteredParts;
        },

        refreshData () {
            this.socketEmit('getPartGroupTypes', null, (results) => {
                this.groupTypes = results;
            });
            this.socketEmit('getPartGroupCategories', null, (results) => {
                this.groupCategories = results;
            });
            this.socketEmit('getPartGroups', null, (results) => {
                this.groups = results;
            });
            this.socketEmit('getPartGroupParts', null, (results) => {
                this.groupParts = results.filter(part => part.active);
            });
            this.socketEmit('getAllParts', null, (results) => {
                this.partData = results;
            });
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
    },
    mounted (){
        this.refreshData();
        window.satpartgroupsvm = this;
    }
};

</script>

<style lang="scss">
.numberInput {
    width: 100px;
}
</style>
